import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/SimpleFiveColumn.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";

import CustomizeIconImage from "images/customize-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import { SectionHeading } from "components/misc/Headings";

const PrimaryLink = styled(PrimaryLinkBase).attrs({
  className: "primary-link",
})`
  && {
    ${tw`rounded-full text-gray-100`}
  }
`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}:not(.primary-link), ${LogoLink} {
    ${tw`text-gray-900 hover:text-primary-500 hover:border-primary-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-500 hover:text-primary-500`}
  }
`;

const Column = styled.div`
  ${tw`md:w-1/2 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const navLinks = [
  <NavLinks key={1}>
    <NavLink href='/'>Home</NavLink>
    <NavLink href='/about'>Leistungen</NavLink>
    <NavLink href='/about'>Über uns</NavLink>
    <NavLink href='/contact'>Kontakt</NavLink>
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink href='/#contact'>Kontaktiere uns</PrimaryLink>
  </NavLinks>,
];

export default () => {
  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Reperaturen aller art",
      description:
        "Fugenarbeiten Küche, Bad usw.. Briefkasten reperaturen Duschkabinen Dichtungen",
    },
    {
      imageSrc: SupportIconImage,
      title: "Küchenservice",
      description:
        "Wasserschaden? Schrankfronten beschädigt? Neues Müllsystem? Schublade klemmt? Modernere Griffe?",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Fensterservice",
      description:
        "Fensterflügel klemmt?   Defektes Glas? ​Öffnung geht streng? Getriebe Defekt? Einbruch?",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Türenservice",
      description:
        "Schloss klemmt? Zylinder wechseln? Einbruch? Schlüssel verloren? Türe streift?",
    },
  ];

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} />

      <ThreeColumnContainer>
        <Heading>
          Unsere <span tw='text-primary-500'>Dienstleistungen</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className='imageContainer'>
                <img
                  src='oldaco_logo.png'
                  alt='logo'
                />
              </span>
              <span className='textContainer'>
                <span className='title'>{card.title || "Fully Secure"}</span>
                <p className='description'>
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Footer from "components/footers/SimpleFiveColumn.js";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";

import { SectionHeading } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography.js";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const PrimaryLink = styled(PrimaryLinkBase).attrs({
  className: "primary-link",
})`
  && {
    ${tw`rounded-full text-gray-100`}
  }
`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}:not(.primary-link), ${LogoLink} {
    ${tw`text-gray-900 hover:text-primary-500 hover:border-primary-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-500 hover:text-primary-500`}
  }
`;

const Column = styled.div`
  ${tw`md:w-1/2 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const Heading = tw(SectionHeading)`w-full`;

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-4 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const navLinks = [
  <NavLinks key={1}>
    <NavLink href='/'>Home</NavLink>
    <NavLink href='/services'>Leistungen</NavLink>
    <NavLink href='/about'>Über uns</NavLink>
    <NavLink href='/contact'>Kontakt</NavLink>
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink href='/#contact'>Kontaktiere uns</PrimaryLink>
  </NavLinks>,
];

const Description = tw(SectionDescription)`w-full text-center`;

const Container = tw.div`relative`;

const HeaderWrapper = tw.div`flex flex-col items-center justify-center pt-20`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

export default ({
  heading = "KONTAKT",
  description = "In deiner Nähe Aargau/Zürich",
  textOnLeft = true,
}) => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} />
      <Container id='contact'>
        <HeaderWrapper>
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderWrapper>
        <TwoColumn>
          <ImageColumn>
            <APIProvider apiKey={""} libraries={["marker"]}>
              <Map
                mapId={"bf51a910020fa25a"}
                defaultZoom={12}
                defaultCenter={{ lat: 47.425003, lng: 8.312988 }}
                gestureHandling={"greedy"}
                disableDefaultUI
              >
                <Marker
                  position={{ lat: 47.425003, lng: 8.312988 }}
                  title={"clickable google.maps.Marker"}
                />
              </Map>
            </APIProvider>
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Card>
                <span className='textContainer'>
                  <span className='title'>Oldaco Schreinerservice</span>
                  <p className='description'>
                    <Address>
                      <AddressLine>Steigässli 2</AddressLine>
                      <AddressLine>5452 Oberrohrdorf</AddressLine>
                    </Address>
                    <Email>​info@oldaco.ch</Email>
                    <Phone>Tel / Whatsapp 078 263 25 66</Phone>
                    <Phone>www.oldaco.ch</Phone>
                  </p>
                </span>
              </Card>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

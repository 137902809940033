import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Kontaktiere uns",
  heading = (
    <>
      Kontaktieren Sie
      <wbr /> uns.
    </>
  ),
  description = "Haben Sie Fragen oder Anliegen? Zögern Sie nicht, uns zu kontaktieren! Unser Team steht Ihnen gerne zur Verfügung, um Ihnen weiterzuhelfen. Wir freuen uns darauf, von Ihnen zu hören.",
  submitButtonText = "Senden",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id='contact'>
      <TwoColumn>
        <ImageColumn>
          <APIProvider apiKey={""} libraries={["marker"]}>
            <Map
              mapId={"bf51a910020fa25a"}
              defaultZoom={12}
              defaultCenter={{ lat: 47.425003, lng: 8.312988 }}
              gestureHandling={"greedy"}
              disableDefaultUI
            >
              <Marker
                position={{ lat: 47.425003, lng: 8.312988 }}
                title={"clickable google.maps.Marker"}
              />
            </Map>
          </APIProvider>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
              <Input
                type='email'
                name='email'
                placeholder='Ihre Email-Adresse'
              />
              <Input type='text' name='name' placeholder='Name' />
              <Input type='text' name='subject' placeholder='Betreff' />
              <Textarea name='message' placeholder='Ihre Nachricht' />
              <SubmitButton type='submit'>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://www.oldaco.ch/uploads/1/4/3/7/143742237/editor/csm-innenseite-holz-279d679ca4.jpg?1670441820",
      subtitle: "",
      title: "Reparaturservice",
      description:
        "Ob zerbrochenes Glas, ausgebrochene Türscharniere oder ein Wasserschaden in der Küche - wir sind zur Stelle, um nahezu jede Reparatur durchzuführen, die noch zu retten ist. Unser Ziel ist es, Probleme möglichst vor Ort zu lösen. Sollte sich der Aufwand jedoch als nicht lohnenswert erweisen, bieten wir auch den Austausch durch neue Komponenten an.",
      url: "mailto:oliver@oldaco.ch",
    },

    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1677700640577-5a466e497411?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      subtitle: "",
      title: "Denkmalschutz",
      description:
        "Entdecken Sie unsere spezialisierten Schreinerdienstleistungen, die den Denkmalschutz berücksichtigen. Unser erfahrenes Team bewahrt historische Gebäude und Möbelstücke in ihrem ursprünglichen Glanz. Wir bieten massgeschneiderte Lösungen für die Restaurierung und Pflege von kulturellem Erbe. Kontaktieren Sie uns, um mehr über unsere einzigartigen Schreinerarbeiten zu erfahren.",
      url: "mailto:oliver@oldaco.ch",
    },

    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1683141430181-f4cfe16b1e62?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      subtitle: "",
      title: "Fugenservice",
      description:
        "Selbst wenn Silikonfugen im Bad oder in der Küche schimmlig, verfärbt oder sogar zerissen sind, brauchen Sie sich keine Sorgen zu machen. Wir stehen bereit, um sämtliche Fugen mit einer passenden Farbauswahl für Sie zu erneuern.",
      url: "mailto:oliver@oldaco.ch",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Leistungen</HeadingTitle>
          <HeadingDescription>
            Hier sind einige der beliebtesten Dienstleistungen, die wir
            anbieten!
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Anfrage</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColWithSideImage.js";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";

const PrimaryLink = styled(PrimaryLinkBase).attrs({
  className: "primary-link",
})`
  && {
    ${tw`rounded-full text-gray-100`}
  }
`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}:not(.primary-link), ${LogoLink} {
    ${tw`text-gray-900 hover:text-primary-500 hover:border-primary-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-500 hover:text-primary-500`}
  }
`;

const navLinks = [
  <NavLinks key={1}>
    <NavLink href='/'>Home</NavLink>
    <NavLink href='/services'>Leistungen</NavLink>
    <NavLink href='/about'>Über uns</NavLink>
    <NavLink href='/contact'>Kontakt</NavLink>
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink href='/#contact'>Kontaktiere uns</PrimaryLink>
  </NavLinks>,
];

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} />
      <MainFeature1
        subheading={<Subheading>Über oldaco</Subheading>}
        heading='Willkommen bei Schreinerei Oldaco.'
        description='Ihr verlässlicher Partner für Schreinerarbeiten im Kanton Aargau!
        Unsere Schreinerei bietet professionelle Serviceleistungen für Fenster, Türen, Küchen und mehr. Mit unserer langjährigen Erfahrung und Leidenschaft für Handwerkskunst erfüllen wir Ihre individuellen Wünsche mit höchster Präzision.
        Wir legen grossen Wert auf Qualität und Kundenzufriedenheit. Unser erfahrenes Team steht für sorgfältige Arbeit und Liebe zum Detail, sei es bei Reparaturen, Installationen oder maßgeschneiderten Projekten.
        Als vertrauenswürdiger Partner in der Region bieten wir faire Preise und garantieren höchste Standards. Ihre Zufriedenheit steht bei uns an erster Stelle, daher bieten wir maßgeschneiderte Lösungen von der Planung bis zur Fertigstellung.
        Kontaktieren Sie uns noch heute, um mehr über unsere Services zu erfahren und ein unverbindliches Angebot zu erhalten. Wir freuen uns darauf, Ihre Wohnträume Realität werden zu lassen!'
        buttonRounded={false}
        primaryButtonText='Kontaktieren Sie uns'
        primaryButtonUrl='/#contact'
        imageSrc='oldaco_logo.png'
      />
      {/* <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      /> */}
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
